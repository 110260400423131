import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Button from "components/Button";
import Dropdown from "components/Dropdown";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>

    <Playground __position={0} __code={'<Button color=\"primary\">primary</Button>{\' \'}\n<Button color=\"secondary\">secondary</Button>{\' \'}\n<Button color=\"success\">success</Button> <Button color=\"info\">info</Button>{\' \'}\n<Button color=\"warning\">warning</Button>{\' \'}\n<Button color=\"danger\">danger</Button> <Button color=\"link\">link</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Button,
      Dropdown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button color="primary" mdxType="Button">primary</Button>{' '}
  <Button color="secondary" mdxType="Button">secondary</Button>{' '}
  <Button color="success" mdxType="Button">success</Button>{' '}
  <Button color="info" mdxType="Button">info</Button>{' '}
  <Button color="warning" mdxType="Button">warning</Button>{' '}
  <Button color="danger" mdxType="Button">danger</Button>{' '}
  <Button color="link" mdxType="Button">link</Button>
    </Playground>
    <Playground __position={1} __code={'<Button.Group>\n  <Button>Left</Button>\n  <Button>Middle</Button>\n  <Button>Right</Button>\n</Button.Group>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Button,
      Dropdown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Button.Group>
    <Button mdxType="Button">Left</Button>
    <Button mdxType="Button">Middle</Button>
    <Button mdxType="Button">Right</Button>
  </Button.Group>
    </Playground>
    <Playground __position={2} __code={'() => {\n  const [dropdownOpen, setDropdownOpen] = React.useState(false)\n  const toggle = () => setDropdownOpen(prevState => !prevState)\n  return (\n    <Button.Dropdown isOpen={dropdownOpen} toggle={toggle}>\n      <Dropdown.Toggle caret>Button Dropdown</Dropdown.Toggle>\n      <Dropdown.Menu>\n        <Dropdown.Item header>Header</Dropdown.Item>\n        <Dropdown.Item disabled>Action</Dropdown.Item>\n        <Dropdown.Item>Another Action</Dropdown.Item>\n        <Dropdown.Item>Another Action</Dropdown.Item>\n      </Dropdown.Menu>\n    </Button.Dropdown>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Button,
      Dropdown,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [dropdownOpen, setDropdownOpen] = React.useState(false);

        const toggle = () => setDropdownOpen(prevState => !prevState);

        return <Button.Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <Dropdown.Toggle caret>
          Button Dropdown
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item header>Header</Dropdown.Item>
          <Dropdown.Item disabled>Action</Dropdown.Item>
          <Dropdown.Item>Another Action</Dropdown.Item>
          <Dropdown.Item>Another Action</Dropdown.Item>
        </Dropdown.Menu>
      </Button.Dropdown>;
      }}
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p>
  <a href="https://reactstrap.github.io/components/buttons/" target="_blank">Reactstrap Button</a> | <a href="https://reactstrap.github.io/components/button-group/" target="_blank">Reactstrap Button Group</a> | <a href="https://reactstrap.github.io/components/button-dropdown/" target="_blank">Reactstrap Button Dropdown</a>
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      